iframe#webpack-dev-server-client-overlay{display:none!important}

.scroll-container {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  
  .scroll-container::-webkit-scrollbar {
    width: 6px; /* Adjust the width of the scrollbar */
  }
  
  .scroll-container::-webkit-scrollbar-thumb {
    background-color: red; /* Change the color of the scrollbar */
    border-radius: 10px; /* Optional: add a border radius for a rounded scrollbar */
  }
  
  .scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: #b30000; /* Darker shade on hover */
  }
  
  .scroll-container::-webkit-scrollbar-track {
    background-color: transparent; /* Background of the scrollbar track */
  }
  hr.projectHr {
    overflow: visible;  
    height: 30px;
    border-style: solid;
    border-color: white;
    border-width: 1px 0 0 0;
    border-radius: 20px;
}
hr.projectHr:before {  
    display: block;
    content: "";
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: white;
    border-width: 0 0 1px 0;
    border-radius: 20px;
}