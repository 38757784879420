.circle {
    background-image: radial-gradient(circle, #3a3a3a69, #3a3a3a69 66%, transparent 66%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7em; /* Adjust the padding to control the size of the circle around the content */
    border-radius: 90%; /* Ensure the shape remains a circle */
}
 
.circle:hover {
    background-image: radial-gradient(circle, #62626269, #62626269 66%, transparent 66%);
}
